import { get, put, post, destroy, BaseUrl } from "./api";

const controller = 'TemplateFileProcess';

export const getWorkflows = async (tags, operand) => {
  let op = "";
  if (!!operand)
    op += `/${operand}`;
  return await get(`${controller}/workflow/${tags}${op}`);
}

export const getWorkflowAccess = async () => {
  return await get(`${controller}/workflow/access`);
}

export const getWorkflowEnvironments = async () => {
  return await get(`${controller}/workflow/environments`);
}

export const getInteractTemplateFileProcessFromTemplateAndFileId = async (templateId, fileId) => {
  return await get(`${controller}/${templateId}/${fileId}`);
}

export const createInteractTemplateFileProcess = async (model) => {
  return await post(`${controller}`, model);
}

export const updateInteractTemplateFileProcess = async (id, model) => {
  return await put(`${controller}/${id}`, model);
}

export const deleteInteractTemplateFileProcess = async (id) => {
  return await destroy(`${controller}/${id}`);
}
