import { useReactOidc } from '@axa-fr/react-oidc-context';
import React, { useState, useContext, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { config } from '../../utils/config';
import { useFeature } from '../../hooks/useFeature';
import crypto from 'crypto'

import { useModalHelper } from "@metaforcelabs/metaforce-core";

import { TailwindUiContext, OidcRoutesContext, TooltipContext, DebugContext, InteractStatusContext } from '../../contexts';

import { getSessionFromLs, setSessionToLs, setSessionIfExistsinLs } from '../../utils/extrasUtils.js'

import { createExtrasSession, validateExtrasSession, closeExtrasSession } from '../../api/extrasSession.js'
import { featureFlags } from '../../utils/features.js'
import { changeCompany } from '../../utils/oidcUtilities';

import './styles.scss';

import { TwNavBar } from "../../components/TailwinNavBar";
import KeyboardEventHandler from "../../components/KeyboardEventHandler";

import { EnvironmentGroupPanel } from "./environmentGroupPanel";

import { useAutoDarkSide, ClearDarkSideSetting, isDarkSideAutoMode, GetDarkSideAutoModeTheme } from "../../hooks/useDarkSide";
import { tailwindUiSettings } from '../../tailwindUiSettings.js';

import Tooltip from 'react-tooltip'

import CreateExtrasSessionDialog from "../../screens/Admin/Extras/createExtrasSessionDialog.js"
import { isDeployEnabled } from '../../api/deployment.js';

const useCustomData = () => {
}


export default function AdminLayout({ nav, subMenuNav, children }) {
  const [colorTheme, setTheme, autoTheme, setAutoTheme, isDarkMode] = useAutoDarkSide();

  const { environment } = useParams();
  const { hasFeature } = useFeature();

  const { debugData, debugDataKeys, getValue, dispatchSetValues, dispatchToogleBool} = useContext(DebugContext);
  const { deployEnabled } = useContext(InteractStatusContext);

  // const { classNames } = useClassNames();
  const { logout } = useReactOidc();
  const oidcRoutesContext = useContext(OidcRoutesContext);

  const createExtrasSessionModalHelper = useModalHelper();

  useEffect(() => setSessionIfExistsinLs(dispatchExSessionValues), []);

  const changeDarkMode = async () => {
    setAutoTheme(false);
    setTheme(colorTheme);
  };
  const clearDarkMode = async () => {
    ClearDarkSideSetting();
    setAutoTheme(isDarkSideAutoMode());
    setTheme(GetDarkSideAutoModeTheme());
  }

  const navigation = [
    // Home
    // {
    //   id: uuidv4(),
    //   name: 'Dashboard',
    //   route: `/`,
    //   current: nav === 'dashboard-old',
    //   featureEnabled: true
    // },
    {
      id: uuidv4(),
      name: 'Home',
      route: `/`,
      current: nav === 'home',
      featureEnabled: true
    },
    // Templates
    {
      id: uuidv4(),
      name: 'Templates',
      route: `/templates/update`,
      current: subMenuNav === "templates",
      featureEnabled: true,
      children: [
        {
          id: uuidv4(),
          name: 'Maintainance',
          route: `/templates/update`,
          current: nav === 'templates_update' || nav === 'templates_publish',
          subMenu: true,
          featureEnabled: true,
          subMenuHide: subMenuNav !== "templates"
        },
      ]
    },
    // Environments
    // {
    //   id: uuidv4(),
    //   name: 'Environments',
    //   route: `/environments/integrations`,
    //   current: subMenuNav === "environments",
    //   featureEnabled: true,
    //   children: [
    //     {
    //       id: uuidv4(),
    //       name: 'Groups',
    //       route: `/environments/groups`,
    //       current: nav === "environments_integrationgroups" || nav === 'environments_integrationgroup',
    //       subMenu: true,
    //       subMenuHide: subMenuNav !== "environments"
    //     },
    //     {
    //       id: uuidv4(),
    //       name: 'Integration',
    //       route: `/environments/integrations`,
    //       current: nav === "environments_integrations" || nav === 'environments_integration' || nav === 'environments_createdeploymentenv',
    //       subMenu: true,
    //       subMenuHide: subMenuNav !== "environments"
    //     },
    //     {
    //       id: uuidv4(),
    //       name: 'Templates',
    //       route: `/environments/templates`,
    //       current: nav === "environments_templates" || nav === 'environments_template' || nav === 'environments_templateserver',
    //       subMenu: true,
    //       subMenuHide: subMenuNav !== "environments"
    //     },
    //     {
    //       id: uuidv4(),
    //       name: 'WebEditor',
    //       route: `/environments/webeditors`,
    //       current: nav === "environments_webeditors" || nav === 'environments_webeditor',
    //       subMenu: true,
    //       subMenuHide: subMenuNav !== "environments"
    //     },
    //     {
    //       id: uuidv4(),
    //       name: 'Archive',
    //       route: `/environments/archieves`,
    //       current: nav === "environments_archieves" || nav === 'environments_archieve',
    //       subMenu: true,
    //       subMenuHide: subMenuNav !== "environments"
    //     },
    //   ]
    // },
    {
      id: uuidv4(),
      name: 'Environments',
      route: `/environments/v2`,
      current: subMenuNav === 'environments_v2',
      featureEnabled: true
    },
    // Attribute Store
    {
      id: uuidv4(),
      name: "Attribute Store",
      route: "/admin/attributestore/production",
      current: subMenuNav === "attributestore",
      featureEnabled: oidcRoutesContext?.userProfile.isSppUser === true,
      children: [
        {
          id: uuidv4(),
          name: "Development",
          featureEnabled: true,
          route: "/admin/attributestore/development",
          current: (nav === 'attributestore_documents' || nav === 'attributestore_document') && environment === 'development',
          subMenu: true,
          subMenuHide: subMenuNav !== "attributestore"
        },
        {
          id: uuidv4(),
          name: "Test",
          featureEnabled: true,
          route: "/admin/attributestore/test",
          current: (nav === 'attributestore_documents' || nav === 'attributestore_document') && environment === 'test',
          subMenu: true,
          subMenuHide: subMenuNav !== "attributestore"
        },
        {
          id: uuidv4(),
          name: "Production",
          featureEnabled: true,
          route: "/admin/attributestore/production",
          current: (nav === 'attributestore_documents' || nav === 'attributestore_document') && environment === 'production',
          subMenu: true,
          subMenuHide: subMenuNav !== "attributestore"
        },
      ],
    },
    // // MFDX
    // {
    //   name: "MFDX",
    //   route: "/admin/mfdx/production",
    //   current: subMenuNav === "mfdx",
    //   featureEnabled: oidcRoutesContext?.userProfile.isSppUser === true,
    //   children: [
    //     {
    //       name: "Production",
    //       featureEnabled: true,
    //       route: "/admin/mfdx/production",
    //       current: (nav === 'mfdx_settings' || nav === 'mfdx_setting') && environment === 'production',
    //       subMenu: true,
    //       subMenuHide: subMenuNav !== "mfdx"
    //     },
    //     {
    //       name: "Test",
    //       featureEnabled: true,
    //       route: "/admin/mfdx/test",
    //       current: (nav === 'mfdx_settings' || nav === 'mfdx_setting') && environment === 'test',
    //       subMenu: true,
    //       subMenuHide: subMenuNav !== "mfdx"
    //     },
    //     {
    //       name: "Development",
    //       featureEnabled: true,
    //       route: "/admin/mfdx/development",
    //       current: (nav === 'mfdx_settings' || nav === 'mfdx_setting') && environment === 'development',
    //       subMenu: true,
    //       subMenuHide: subMenuNav !== "mfdx"
    //     },
    //   ],
    // }
  ];

  if (debugData.hasOwnProperty(debugDataKeys.mf_test) ? debugData[debugDataKeys.mf_test] : false) {
    let extrasChildren = [
      {
        id: uuidv4(),
        name: 'Sandbox',
        route: `/extrs/sandbox`,
        current: nav === "extrs_sandbox",
        subMenu: true,
        subMenuHide: subMenuNav !== "extrs"
      }
    ];
    if (deployEnabled) {
      extrasChildren.push(
        {
          id: uuidv4(),
          name: 'Deployments',
          route: `/extrs/deployments`,
          current: nav === "extrs_deployments",
          subMenu: true,
          subMenuHide: subMenuNav !== "extrs"
        }
      );
      extrasChildren.push(
        {
          id: uuidv4(),
          name: 'Templates',
          route: `/extrs/templates`,
          current: nav === "extrs_templates",
          subMenu: true,
          subMenuHide: subMenuNav !== "extrs"
        }
      );
    }

    extrasChildren.push(
      {
        id: uuidv4(),
        name: 'File Manager',
        route: `/extrs/filemanager`,
        current: nav === "extrs_filemanager",
        subMenu: true,
        subMenuHide: subMenuNav !== "extrs"
      }
    );
    extrasChildren.push(
      {
        id: uuidv4(),
        name: 'File Troubleshoot',
        route: `/extrs/filetroubleshoot`,
        current: nav === "extrs_troubleshoot",
        subMenu: true,
        subMenuHide: subMenuNav !== "extrs"
      }
    );
    extrasChildren.push(
      {
        id: uuidv4(),
        name: 'Environments',
        route: `/extrs/environments`,
        current: nav === "extrs_environments",
        subMenu: true,
        subMenuHide: subMenuNav !== "extrs"
      }
    );

    navigation.push(
      // Extras
      {
        id: uuidv4(),
        name: 'Extras',
        route: `/extrs/sandbox`,
        current: subMenuNav === "extrs",
        featureEnabled: true,
        children: extrasChildren /*[
          {
            id: uuidv4(),
            name: 'Sandbox',
            route: `/extrs/sandbox`,
            current: nav === "extrs_sandbox",
            subMenu: true,
            subMenuHide: subMenuNav !== "extrs"
          },
          {
            id: uuidv4(),
            name: 'Deployments',
            route: `/extrs/deployments`,
            current: nav === "extrs_deployments",
            subMenu: true,
            subMenuHide: subMenuNav !== "extrs"
          },
          {
            id: uuidv4(),
            name: 'Templates',
            route: `/extrs/templates`,
            current: nav === "extrs_templates",
            subMenu: true,
            subMenuHide: subMenuNav !== "extrs"
          },
          {
            id: uuidv4(),
            name: 'File Manager',
            route: `/extrs/filemanager`,
            current: nav === "extrs_filemanager",
            subMenu: true,
            subMenuHide: subMenuNav !== "extrs"
          },
          {
            id: uuidv4(),
            name: 'File Troubleshoot',
            route: `/extrs/filetroubleshoot`,
            current: nav === "extrs_troubleshoot",
            subMenu: true,
            subMenuHide: subMenuNav !== "extrs"
          },
          {
            id: uuidv4(),
            name: 'Environments',
            route: `/extrs/environments`,
            current: nav === "extrs_environments",
            subMenu: true,
            subMenuHide: subMenuNav !== "extrs"
          },
        ]
        */
      }
    );
  }

  const userNavigation = [
    {
      id: uuidv4(),
      name: 'Toggle DarkMode',
      onClick: changeDarkMode
    },
    !autoTheme
      ? {
        id: uuidv4(),
        name: 'Reset darkmode settings',
        onClick: clearDarkMode
      }
      : null,
    {
      id: uuidv4(),
      name: 'Change Company',
      onClick: changeCompany
    },
    {
      id: uuidv4(),
      name: 'Sign out',
      onClick: logout
    }
  ];

  const dispatchExSessionValues = (sessionId) => {
    dispatchSetValues([{key: debugDataKeys.exSession, value: sessionId}, {key: debugDataKeys.mf_test, value: sessionId?.length > 0}]);
  }

  const createOrCloseExtrasSession = async () => {
    var sessionId = getSessionFromLs();
    // var sessionId = getValue(debugDataKeys.exSession);
    var isActive = sessionId?.length > 0;
    if (isActive) {
      await closeExtrasSession(sessionId);
      setSessionToLs("", dispatchExSessionValues);
      // localStorage.setItem('extrasSessionId', "");
      // dispatchSetValues([{key: debugDataKeys.exSession, value: ""}, {key: debugDataKeys.mf_test, value: false}]);
    } else {
      let loginData = {password: ""};
      createExtrasSessionModalHelper.setActivetItem(loginData);
      createExtrasSessionModalHelper.open();
    }
  };

  const createExtrasSessionFromPassword = async (password) => {
    var sessionId = getSessionFromLs();
    // var sessionId = getValue(debugDataKeys.exSession);
    if (sessionId)
    {
      await closeExtrasSession(sessionId);
      setSessionToLs("", dispatchExSessionValues);
      // localStorage.setItem('extrasSessionId', "");
      // dispatchSetValues([{key: debugDataKeys.exSession, value: ""}, {key: debugDataKeys.mf_test, value: false}]);
    }

    if (!!password) {
      const SALT = '$ome$alt';
      const hash = crypto.createHmac('sha256', SALT)
        .update(password)
        .digest('hex');
      var sid = await createExtrasSession(hash);
      if (!!sid) {
        setSessionToLs(sid, dispatchExSessionValues);
        // localStorage.setItem('extrasSessionId', sid);
        // dispatchSetValues([{key: debugDataKeys.exSession, value: sid}, {key: debugDataKeys.mf_test, value: true}]);
        createExtrasSessionModalHelper.close();
      }
    }
  }

  const keyActions = [
    {key: 'ctrl+alt+m', action: async () => await createOrCloseExtrasSession()}
  ]
  
  const onKeyEvent = (key) => {
    const action = keyActions.find(ka => ka.key === key)?.action;
    if (action)
      action();
  }

  return (
      <TailwindUiContext.Provider value={tailwindUiSettings}>
        <div className="relative flex flex-col min-h-screen bg-white dark:bg-gray-900">
          <TwNavBar navigation={navigation} userNavigation={userNavigation} darkMode={{ isDarkMode, changeDarkMode }} helpUrl={config.docsUrl} />

          <main className="main-container min-h-screen bg-brand-background z-0 focus:outline-none h-full overflow-auto dark:bg-gray-800">
            <div className={`max-w-screen-3xl h-full mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8`}>
              <div className="flex flex-col pb-10 h-full">
                <div className={`max-w-screen-3xl h-full`}>
                  <SetTooltipContext>
                    <KeyboardEventHandler
                      handleKeys={keyActions.map(x => x.key)}
                      allowOnlyChildEventForChildren={false}
                      onKeyEvent={onKeyEvent}
                    >
                      {children}

                      <CreateExtrasSessionDialog modalHelper={createExtrasSessionModalHelper} onConfirm={createExtrasSessionFromPassword} />
                    </KeyboardEventHandler>
                  </SetTooltipContext>
                </div>
              </div>
            </div>
          </main>
        </div>
      </TailwindUiContext.Provider>
  );
}


//#region TooltipContext

export const SetTooltipContext = ({ children }) => {
  const [tooltipElements, setTooltipElements] = useState([]);

  const addTooltipElementWithId = (
    id,
    getContent,
    place = "top",
    type = "dark",
    effect = "float",
    haspopup = 'true',
    className = 'z-50'
  ) => {
    if (!id || !getContent)
      return;
    const elemTmp = tooltipElements.find(x => x.id === id);
    if (elemTmp)
      return;

    const elem = {
      id: id,
      element:
        <>
          <Tooltip id={id} place={place} type={type} effect={effect} aria-haspopup={haspopup} className={className}
            getContent={(dataTip) => {
              return getContent(dataTip);
            }}
          />
        </>
    };

    setTooltipElements(prev => {
      return [...prev, elem];
    });
    tooltipElements.push(elem);
  };


  const addTooltipElementWithAnchor = (
    anchor,
    getContent,
    place = "top",
    type = "dark",
    effect = "float",
    haspopup = 'true',
    className = 'z-50'
  ) => {
    if (!anchor || !getContent)
      return;
    const elemTmp = tooltipElements.find(x => x.id === anchor);
    if (elemTmp)
      return;

    const elem = {
      id: anchor,
      element:
        <>
          <Tooltip anchorSelect={anchor} place={place} type={type} effect={effect} aria-haspopup={haspopup} className={className}
            getContent={(dataTip) => {
              return getContent(dataTip);
            }}
          />
        </>
    };

    setTooltipElements(prev => {
      return [...prev, elem];
    });
    tooltipElements.push(elem);
  };

  const elements = tooltipElements?.map(x => x.element);

  return (
    <TooltipContext.Provider
      value={{
        tooltipElements,
        addTooltipElementWithId,
        addTooltipElementWithAnchor
      }}
    >
      {children}
      {elements && elements.map((item) => (
        <>{item}</>
      ))}
    </TooltipContext.Provider>
  );
}

//#endregion
