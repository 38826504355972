import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

import { Form, Formik } from 'formik';
import * as yup from "yup";
import { v4 as uuidv4 } from 'uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import { useToastAction } from '../../../hooks/useToastAction';
import { useClassNames } from "../../../hooks/useClassNames";
import { useFeature } from '../../../hooks/useFeature';
import { useModalHelper } from "@metaforcelabs/metaforce-core";

import { TwTableContext } from '../../../contexts';

import { getInteractEnvironments, getEnvironmentGroup } from '../../../api/interact';
import { getInteractTemplateEnvironmentNoFiles } from "../../../api/interactTemplateEnvironment.js";
import { getInteractTemplateFile, getTemplateDocumentsEx } from "../../../api/interactTemplateFile.js";
import { getWorkflowAccess, getWorkflows, getWorkflowEnvironments, getInteractTemplateFileProcessFromTemplateAndFileId, createInteractTemplateFileProcess, updateInteractTemplateFileProcess, deleteInteractTemplateFileProcess } from "../../../api/interactTemplateFileProcess.js";

import { EnvironmentGroupContainerCard } from "../Environments/environmentGroupContainerCard.js"
import Modal from '../../../components/Modal';
import GenericModal from '../../../components/Modals/genericModal';

import { Button as CmpButton } from "../../../components/Button";
import { Label as CmpLabel } from "../../../components/Label";
import { Select as CmpSelect } from "../../../components/Select";
import { Panel as CmpPanel } from "../../../components/Panel";
import MenuContextList from "../../../components/MenuContextList";
import { TwPage } from "../../../components/TailwindPage";
import { TwTable } from "../../../components/TailwindTable";
import { TwFormEx } from '../../../components/TailwindForm';

export default function LogicFileProcessManager() {
  const { groupId, environmantId, fileId } = useParams();

  const refMenuContainerTo = useRef();

  const { hasFeature } = useFeature();
  const history = useHistory();
  const { classNames } = useClassNames();
  const loadAction = useToastAction();
  const deleteAction = useToastAction();

  const editLogicDocumentProcessesModalHelper = useModalHelper();

  const [initialized, setInitialized] = useState(false)
  const [hasWorkflowLicense, setHasWorkflowLicense] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedProcesses, setSelectedProcesses] = useState([])

  const [environmentGroup, setEnvironmentGroup] = useState(null)
  const [environments, setEnvironments] = useState(null)
  const [environment, setEnvironment] = useState(null)
  const [logicFile, setLogicFile] = useState(null)
  const [workflows, setWorkflows] = useState([])

  const [paginationTotalCount, setPaginationTotalCount] = useState(0);
  const [paginationCurrentPage, setPaginationCurrentPage] = useState(0);
  const [paginationItemsPerPage, setPaginationItemsPerPage] = useState(10);

  const [pageProcesses, setPageProcesses] = useState([])
  const [processes, setProcesses] = useState([])

  const tableSettings = {
    type: TwTable.types.simple
  }

  const workflowTriggers = [
    "trig:webeditor:pre-process",
    "trig:webeditor:pre-post-process",
    "trig:webeditor:post-process"
  ]

  const loadBaseData = async () => {
    loadAction.execute(async () => {
      const allEnvs = await getInteractEnvironments();
      // console.log("LogicFileProcessManager", "loadBaseData", "allEnvs", allEnvs);

      const hasWfAccess = await getWorkflowAccess();
      // console.log("LogicFileProcessManager", "loadBaseData", "hasWfAccess", hasWfAccess);
      setHasWorkflowLicense(hasWfAccess);

      if (hasWfAccess) {
        // console.log("LogicFileProcessManager", "loadBaseData", "calling getWorkflowEnvironments");
        const wfEnvs = await getWorkflowEnvironments();
        // console.log("LogicFileProcessManager", "loadBaseData", "envs", wfEnvs);
        setEnvironments(wfEnvs);

        const tplEnv = await getInteractTemplateEnvironmentNoFiles(environmantId);
        // console.log("LogicFileProcessManager", "loadBaseData", "tplEnv", tplEnv);
        const env = allEnvs.find(({ value }) => value === tplEnv.customerEnvironment);
        // console.log("LogicFileProcessManager", "loadBaseData", "env", env);
        setEnvironment({template: tplEnv, environment: env});

        const envGrp = await getEnvironmentGroup(groupId);
        setEnvironmentGroup(envGrp);

        const file = await getInteractTemplateFile(fileId);
        setLogicFile(file);

        // console.log("LogicFileProcessManager", "loadBaseData", "envGrp", envGrp, "tplEnv", tplEnv, "file", file);

        const [wfs] = await Promise.all([getWorkflows(workflowTriggers.join(","))]);
        // console.log("LogicFileProcessManager", "loadBaseData", "wfs", wfs);
        setWorkflows(wfs);

        const existingProcesses = await getInteractTemplateFileProcessFromTemplateAndFileId(tplEnv.id, file.id);

        // console.log("LogicFileProcessManager", "loadBaseData", "existingProcesses", existingProcesses);

        const docs = await getTemplateDocumentsEx(file.id);
        // console.log("LogicFileProcessManager", "loadBaseData", "docs", docs);
        const procs = docs.map(d => {
          const existing = existingProcesses.find(p => p.documentName === d.name);
          if (existing) {
            existing["label"] = d.label;
            existing["tmpId"] = uuidv4();
            return existing;
          }
          else
            return {tmpId: uuidv4(), documentName: d.name, label: d.label, fileId: file.id, templateId: tplEnv.id};
        });

        // console.log("LogicFileProcessManager", "loadBaseData", "processes", procs, "pageDocs", procs.slice(0, paginationItemsPerPage));

        setProcesses(procs);

        setPaginationTotalCount(procs.length);
        setPageProcesses(procs.slice(0, paginationItemsPerPage));
      }
      setInitialized(true);
    }, "Failed to load (LogicFileProcessManager)")
  }

  useEffect(() => {
    if (!fileId || !environmantId)
      return;

    loadBaseData();
  }, [environmantId, fileId]);
  
  useEffect(() => {
    const start = paginationCurrentPage * paginationItemsPerPage;
    // console.log("LogicFileProcessManager", "useEffect[paginationCurrentPage, paginationItemsPerPage]", "documents", processes, "start", start, "pageDocs", processes.slice(start, start + paginationItemsPerPage));
    setPageProcesses(processes.slice(start, start + paginationItemsPerPage));
  }, [paginationCurrentPage, paginationItemsPerPage]);

  const onRowClick = (item) => {
    // console.log("LogicFileProcessManager", "onRowClick", "item", item);
  }

  const onEditItem = (item, env) => {
    editLogicDocumentProcessesModalHelper.setActivetItem({
      environment: environment, 
      item: item,
      logicFile: logicFile,
      environmentGroup: environmentGroup, 
      workflows: workflows,
      environments: environments
    });
    editLogicDocumentProcessesModalHelper.open();
  }
  
  const onRemoveItem = (item, env) => {
    setSelectedProcesses([item]);
    setOpenDeleteConfirmation(true);
  }
  
  const handleProcessDelete = async () => {
    deleteAction.execute(async () => {
      await Promise.all(selectedProcesses.map(p => deleteInteractTemplateFileProcess(p.id)));
      await loadBaseData();
      setOpenDeleteConfirmation(false);
      setSelectedProcesses([]);
    }, "Failed to delete Processes for Logic File Document", "Successfully deleted the Processes for Logic File Document")
  }

  const getItemActions = (item, env) => {
    var actions = environmentGroup.isVirtual
     ? []
     : [
      {
        name: "Edit",
        onClick: () => onEditItem(item, env)
      }, {
        name: "Remove",
        onClick: () => onRemoveItem(item, env),
        disabled: !item?.id
      }

    ];
    return actions;
  }

  const isString = (value) => {
    return value => typeof value === 'string' || value instanceof String;    
  }

  const createOrUpdateItem = async (item, values) => {
    if (isString(values.workflowEnvironment))
      values.workflowEnvironment = parseInt(values.workflowEnvironment);

    // console.log("LogicFileProcessManager", "createOrUpdateItem", "item", item, "values", values);
    setIsSaving(true);
    let success = false;
    try
    {
      if (!!item.id)
        await updateInteractTemplateFileProcess(item.id, values);
      else
        await createInteractTemplateFileProcess(values);
      await loadBaseData();
      success = true;
    }
    catch (err)
    {
      if (err)
        toast.error(err);
    }
    finally
    {
      if (success)
        editLogicDocumentProcessesModalHelper.close();
      setIsSaving(false);
    }
  }

  const getWorkflowName = (wfId) => {
    wfId = wfId || "";
    var wf = workflows.find(w => w.id === wfId);
    return wf?.name || wfId;
  }

  const getWorkflowEnvironmentName = (envId) => {
    var wfe = environments.find(x => x.value === envId);
    return wfe?.name || envId;
  }

  const fullPanelWidth = true;

  return (
    <>
      <TwPage>
        <TwPage.Header>
          <TwPage.Header.Hdr.Fixed
            title={`Manage Processes for logic file: ${logicFile?.fileName || ""}`}
            description={`Process overview for logic file ${logicFile?.fileName || ""}`}
          />
        </TwPage.Header>

        {initialized && (
          <>
            <EnvironmentGroupContainerCard
              group={environmentGroup}
              collapsable={false}
            >
              <div className="mt-8 flow-root">
                <div className={`${fullPanelWidth ? "flex-col tplmtn:flex-row place-content-center" : "flex-col tplmtn:flex-row place-content-center"} flex gap-4 xl:gap-8`}>
                  <CmpPanel additionalClass={`${fullPanelWidth ? "flex-1 mx-12" : "flex-none"}`}>
                    <CmpLabel text={environment?.environment?.name || ""} additionalClass='text-lg font-bold' />

                    {!hasWorkflowLicense && (
                      <CmpLabel type={CmpLabel.types.warn} additionalClass='mt-8 mb-4'>
                        <p className='text-lg font-bold'>No workflow license available</p>
                        <p>The process feature requires a license for the workflow application</p>
                      </CmpLabel>
                    )}

                    {hasWorkflowLicense && (
                      <TwTableContext.Provider value={tableSettings}>
                        <div ref={refMenuContainerTo}>
                          <TwTable>
                            <TwTable.Head>
                                <TwTable.Header additionalClass={'sm:pl-6 h-14 uppercase'}>
                                  Name
                                </TwTable.Header>
                                <TwTable.Header additionalClass={'sm:pl-6 uppercase'}>
                                  Workflow Environement
                                </TwTable.Header>
                                <TwTable.Header additionalClass={'sm:pl-6 uppercase'}>
                                  Pre-process
                                </TwTable.Header>
                                <TwTable.Header additionalClass={'sm:pl-6 uppercase'}>
                                  Pre-post-process
                                </TwTable.Header>
                                <TwTable.Header additionalClass={'sm:pl-6 uppercase'}>
                                  Post-process
                                </TwTable.Header>
                                <TwTable.Header additionalClass={'sm:pl-6 uppercase'}>
                                  Created
                                </TwTable.Header>
                                <TwTable.Header additionalClass={'sm:pl-6 uppercase'}>
                                  Updated
                                </TwTable.Header>
                                <TwTable.Header additionalClass={'sm:pl-6 uppercase'}>
                                </TwTable.Header>
                                <TwTable.Header additionalClass='w-2' />
                              </TwTable.Head>
                              <TwTable.Body noDivider={true}>
                              {!!pageProcesses && pageProcesses.map((item, idx) => (
                                <TwTable.BodyRow key={`ff${item.tmpId}-m`} usePointerCursonOnHover={false} onClick={() => onRowClick(item)} additionalClass={classNames(
                                  "",
                                  "h-12 hover:bg-gray-100 cursor-default"
                                )}
                              >
                                <TwTable.BodyCol>
                                  {item.documentName}
                                </TwTable.BodyCol>
                                <TwTable.BodyCol>
                                  {getWorkflowEnvironmentName(item.workflowEnvironment)}
                                </TwTable.BodyCol>
                                <TwTable.BodyCol>
                                  {getWorkflowName(item.preProcessWorkflowId)}
                                </TwTable.BodyCol>
                                <TwTable.BodyCol>
                                  {getWorkflowName(item.prePostProcessWorkflowId)}
                                </TwTable.BodyCol>
                                <TwTable.BodyCol>
                                  {getWorkflowName(item.postProcessWorkflowId)}
                                </TwTable.BodyCol>
                                <TwTable.BodyCol>
                                </TwTable.BodyCol>
                                <TwTable.BodyCol>
                                </TwTable.BodyCol>
                                <TwTable.BodyCol additionalClass='w-2'>
                                  <MenuContextList
                                    actions={getItemActions(item, environment)}
                                    handleDelete="false"
                                    refContainer={refMenuContainerTo}
                                  />
                                </TwTable.BodyCol>
                              </TwTable.BodyRow>
                            ))}
                              </TwTable.Body>
                              {paginationTotalCount > paginationItemsPerPage && (
                                <TwTable.Foot>
                                  <TwTable.FootCol colSpan="20">
                                    <TwTable.Pagination 
                                      numPages={Math.ceil(paginationTotalCount / paginationItemsPerPage)} 
                                      currPage={paginationCurrentPage} 
                                      setCurrPage={setPaginationCurrentPage} 
                                      itemsPerPage={paginationItemsPerPage} 
                                      setItemPerPage={setPaginationItemsPerPage} 
                                      maxItems={paginationTotalCount} 
                                      pageRanges={[5, 10, 25, 50]}
                                      boundaryCount={2} 
                                    />
                                  </TwTable.FootCol>
                                </TwTable.Foot>
                              )}                          
                            </TwTable>
                          </div>
                        </TwTableContext.Provider>
                      )}
                    </CmpPanel>
                </div>
              </div>
              <div className='h-6'></div>
            </EnvironmentGroupContainerCard>

            <div className='flex mt-8'>
              <div className='flex-1' />
              <CmpButton
                variant={CmpButton.variants.primary}
                // className="flex-none w-32 bg-white text-gray-900 hover:bg-gray-100 hover:bg-text-white dark:bg-gray-900 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                //disabled={selectedFiles.length === 0}
                onClick={() => history.goBack()}
              >
                Back
              </CmpButton>
            </div>
          </>
        )}
      </TwPage>

      <EditLogicDocumentProcessesModal
        title={`Edit Processes for document`}
        modalHelper={editLogicDocumentProcessesModalHelper}
        onConfirm={createOrUpdateItem}
        isSaving={isSaving}
        workflowTriggers={workflowTriggers}
      />

      <GenericModal open={openDeleteConfirmation} setOpen={setOpenDeleteConfirmation}
        onConfirm={handleProcessDelete}
        onCancel={() => {
            setOpenDeleteConfirmation(false);
            setSelectedProcesses([]);
        }}
        showCancelButton={true}
        confirmButtonText={'Delete'}
        title="Delete Processes for Logic File Document">
        <div className="mt-4">
          <p className="text-sm text-gray-500">
              Are you sure you want to delete the selected Processes for Logic File Document?
          </p>
        </div>
      </GenericModal>
    </>
  );
}

function EditLogicDocumentProcessesModal({ modalHelper, title, onConfirm, isSaving, workflowTriggers }) {
  const { classNames } = useClassNames();

  const history = useHistory();

  const [isBusy, setIsBusy] = useState(false)
  const [busyMessage, setBusyMessage] = useState("")

  const [wfPreProcess, setWfPreProcess] = useState([])
  const [wfPostPreProcess, setWfPostPreProcess] = useState([])
  const [wfPostProcess, setWfPostProcess] = useState([])

  useEffect(() => {
    setIsBusy(isSaving);
    setBusyMessage(isSaving ? `Saving processes for document ${modalHelper.activeItem?.logicFile.fileName}...` : null);
  }, [isSaving]);

  useEffect(async () => {
    if (modalHelper.activeItem) {
      setBusyMessage("Loading processess for logic file...");
      setIsBusy(true);

      try {
        setWfPreProcess(filterWorkflowsAndAddDefault(modalHelper.activeItem?.workflows, workflowTriggers[0]));
        setWfPostPreProcess(filterWorkflowsAndAddDefault(modalHelper.activeItem?.workflows, workflowTriggers[1]));
        setWfPostProcess(filterWorkflowsAndAddDefault(modalHelper.activeItem?.workflows, workflowTriggers[2]));
      } finally {
        setIsBusy(false);
        setBusyMessage(null);
      }
    }
  }, [modalHelper.activeItem])

  const filterWorkflowsAndAddDefault = (workflows, filter) => {
    const defaultList = [{id: '', name: ''}];
    var list = defaultList.concat(workflows.filter(x => !!x.workflowTags.find(z => z === filter)) || []);
    return list.map(x => {x["value"] = x.id; return x});
  }

  const handleSubmit = async (values) => {
    await onConfirm(modalHelper.activeItem?.item, values);
  }

  return (
    <Modal
      isOpen={modalHelper.isOpen}
      onClose={() => { modalHelper.close() }}
      size={'large'}
      title={title}
    >
      { isBusy && (
        <>
          <div className="flex justify-center">
            <FontAwesomeIcon icon={faSpinner} className="text-white" size="4x" spin bounce />
          </div>
          <div className="flex justify-center mt-4">
            <CmpLabel text={busyMessage} />
          </div>
        </>
      )}

      { !isBusy && (
        <>
          <CmpLabel text="Attaching workflows to:" textClass='text-sm'/>
          <CmpLabel text={`${modalHelper.activeItem?.environment.environment.name} / ${modalHelper.activeItem?.logicFile.fileName} / ${modalHelper.activeItem?.item.documentName}`} textClass='text-lg font-bold'/>

          <div className="space-y-10 divide-y divide-gray-900/10">
            <Formik
              // enableReinitialize={true}
              initialValues={{
                fileId: modalHelper.activeItem?.logicFile.id,
                templateId: modalHelper.activeItem?.environment.template.id,
                documentName: modalHelper.activeItem?.item.documentName,
                environmentGroupId: modalHelper.activeItem?.environmentGroup.id,
                workflowEnvironment: parseInt(modalHelper.activeItem?.item.workflowEnvironment || modalHelper.activeItem?.environments[0].value || 1),
                preProcessWorkflowId: modalHelper.activeItem?.item.preProcessWorkflowId,
                prePostProcessWorkflowId: modalHelper.activeItem?.item.prePostProcessWorkflowId,
                postProcessWorkflowId:  modalHelper.activeItem?.item.postProcessWorkflowId,
              }}
              validationSchema={yup.object().shape({
                preProcessWorkflowId: yup.string(),
                prePostProcessWorkflowId: yup.string(),
                postProcessWorkflowId: yup.string()
              }).test(
                'processesTest',
                null,
                (obj) => {
                  let processCount = 0;
                  if (!!obj.preProcessWorkflowId) {
                    processCount += 1;
                  }
                  if (!!obj.prePostProcessWorkflowId) {
                    processCount += 1;
                  }
                  if (!!obj.postProcessWorkflowId) {
                    processCount += 1;
                  }
                  if (processCount >= 1) {
                      return true;
                  }

                  return new yup.ValidationError(
                      'Select at least one process',
                      null,
                      'preProcessWorkflowId'
                  );
                })
              }
              onSubmit={handleSubmit}
            >
              {({ values, errors, ...props }) => (
                <Form>
                  <div className="space-y-4">
                    <TwFormEx.Section />
                    <TwFormEx.Section label="Environment" separator={false}>
                      <CmpSelect
                        className={"px-0"}
                        label="Workflow Environment"
                        name="workflowEnvironment"
                        value={values?.workflowEnvironment}
                        onChange={props.handleChange}
                        options={[...modalHelper.activeItem?.environments || []]}
                        error={errors?.workflowEnvironment}
                      />
                    </TwFormEx.Section>
                    <TwFormEx.Section label="Processes" separator={false}>
                      <CmpSelect
                          className={"px-0"}
                          label="Pre-Process"
                          name="preProcessWorkflowId"
                          value={values?.preProcessWorkflowId}
                          onChange={props.handleChange}
                          options={wfPreProcess}
                          error={errors?.preProcessWorkflowId}
                        />
                      <CmpSelect
                          className={"px-0"}
                          label="Pre-Post-Process"
                          name="prePostProcessWorkflowId"
                          value={values?.prePostProcessWorkflowId}
                          onChange={props.handleChange}
                          options={wfPostPreProcess}
                          error={errors?.prePostProcessWorkflowId}
                        />
                      <CmpSelect
                          className={"px-0"}
                          label="Post-Process"
                          name="postProcessWorkflowId"
                          value={values?.postProcessWorkflowId}
                          onChange={props.handleChange}
                          options={wfPostProcess}
                          error={errors?.postProcessWorkflowId}
                        />
                    </TwFormEx.Section>
                  </div>

                  <div className='mt-8 flex gap-x-4 justify-end'>
                    <CmpButton
                      variant={CmpButton.variants.secondary}
                      className="justify-center"
                      disabled={false}
                      onClick={() => {
                        modalHelper.close();
                      }}
                    >
                      Cancel
                    </CmpButton>
                    <CmpButton
                      variant={CmpButton.variants.primary}
                      className="justify-center"
                      disabled={false}
                      type={"submit"}
                    >
                      Save
                    </CmpButton>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </>
      )}
    </Modal>
  )
}