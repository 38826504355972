import React, { useEffect, useState, useContext } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import {
  AuthenticationProvider,
  WebStorageStateStore,
  oidcLog,
  useReactOidc
} from '@axa-fr/react-oidc-context';
import { OidcSecure } from '@axa-fr/react-oidc-context';
import { getUserManager } from '@axa-fr/react-oidc-core';
import i18next from 'i18next';
import { WhiteLabelProvider } from '@metaforcelabs/metaforce-core';
import { I18nextProvider } from 'react-i18next';
import { RecoilRoot } from 'recoil';

import { OidcRoutesContext, CurrentDataContext, DebugContext, InteractStatusContext } from './contexts';

import { useLicense } from './hooks/useLicense';
import { useFeature } from './hooks/useFeature';
import { useToastAction } from './hooks/useToastAction';

import { setAuthHeader } from './api/api';
import { getCustomerInfo } from './api/customer';
import { getUserProfile } from './api/userProfile';


import AdminLayout from './layouts/AdminLayout';
import ErrorLayout from './layouts/ErrorLayout';

import {InteractAdminAppContextProvider} from './contexts/providers/interactAdminAppContextProvider.js'

import Dashboard from './screens/Admin/Dashboard';
import Dashboardv2 from './screens/Admin/Dashboard/dashboardv2.js';
// import TemplatesOverview from './screens/Admin/Templates/Overview';
// import TemplatesEnvironmentList from './screens/Admin/Environments/templateList';
// import TemplatesEnvironment from './screens/Admin/Environments/template';
import TemplatesServer from './screens/Admin/Environments/templateServer';
import TemplatesServerTroubleShoot from './screens/Admin/Environments/templateServerTroubleshoot.js';
import TemplatesMaintainance from './screens/Admin/Templates/maintainance';
import TemplatesPublish from './screens/Admin/Templates/maintainanceTemplate';
import LogicFileProcessManager from './screens/Admin/Templates/logicFileProcessManager.js';

// import EnvironmentsOverview from './screens/Admin/Environments/Overview';
// import EnvironmentsIntegrationList from './screens/Admin/Environments/integrationList';
// import EnvironmentsIntegration from './screens/Admin/Environments/integration';
import EnvironmentsCreateDeploymentEnv from './screens/Admin/Environments/integrationCreateDeploymentEnv.js';
import EnvironmentsCreateDeploymentSrv from './screens/Admin/Environments/integrationCreateDeploymentSrv.js';
// import EnvironmentsGroups from './screens/Admin/Environments/environmentGroupList';
import EnvironmentsGroup from './screens/Admin/Environments/environmentGroup';
// import EnvironmentsWebEditorList from './screens/Admin/Environments/webEditorList';
// import EnvironmentsWebEditor from './screens/Admin/Environments/webEditor';
// import EnvironmentsArchieveList from './screens/Admin/Environments/archieveList';
// import EnvironmentsArchieve from './screens/Admin/Environments/archieve';

import { AttributeStoreDocumentList } from './screens/Admin/AttributeStore/documentList';
import { AttributeStoreDocument } from './screens/Admin/AttributeStore/document';

// import MfdxSettings from './screens/Admin/Mfdx/Settings';
// import { MfdxDocument } from './screens/Admin/Mfdx/Setting';

//import ExtrasTest from './screens/Admin/Extras/test.js';
import ExtrasDeployments from './screens/Admin/Extras/deployments.js';
import ExtrasTemplates from './screens/Admin/Extras/templates.js';
import ExtrasEnvironments from './screens/Admin/Extras/environments.js';
import ExtrasSandbox from './screens/Admin/Extras/sandbox.js';
import ExtrasFileManager from './screens/Admin/Extras/fileManager.js';
import ExtrasFileTroubleshoot from './screens/Admin/Extras/fileTroubleshoot.js';

import { MissingLicense } from './screens/Errors/MissingLicense';
import { NotFound } from './screens/Errors/NotFound';
import { Unauthorized } from './screens/Errors/Unauthorized';
import Oidc from './screens/Oidc';
import { Toaster } from './toaster';
import common_en from './translations/en/common.json';
import home_en from './translations/en/home.json';
import overview_en from './translations/en/overview.json';
import { config, oidcConfig } from './utils/config';

import { useAutoDarkSide } from "./hooks/useDarkSide";

import _ from "lodash"; // cool kids know _ is low-dash
import * as Constants from './utils/constants'
import Environmentsv2 from './screens/Admin/Environments/environmentsv2.js';
import EditEnvrionment from './screens/Admin/Environments/editEnvrionment';

// import useDarkSide from "./hooks/useDarkSide";

i18next.init({
  interpolation: {
    escapeValue: false
  },
  lng: 'en',
  resources: {
    en: {
      common: common_en,
      home: home_en,
      overview: overview_en
    }
  }
});

function App() {
  const [colorTheme, setTheme, autoTheme, setAutoTheme] = useAutoDarkSide();

  const oidcRoutesContext = useContext(OidcRoutesContext);
  const currentDataContext = useContext(CurrentDataContext);
  // const { deployEnabled } = useContext(InteractStatusContext);


  const isSppUserGuard = async () => {

    const userProfilePromise = getUserProfile();
    const res = await Promise.all([userProfilePromise]);
    const userProfile = res[0];

    return userProfile?.isSppUser || false;
  };
  

  return (
    <div className="app min-h-screen bg-brand-background dark:bg-gray-800">
      <RecoilRoot>
        <Router>
          <AuthenticationProvider
            configuration={oidcConfig}
            loggerLevel={oidcLog.ERROR}
            isEnabled={true}
            UserStore={WebStorageStateStore}
            callbackComponentOverride={Oidc}
            notAuthenticated={() => {
              return <h1>Not Authenticated.</h1>;
            }}
            notAuthorized={() => {
              return <h1>Not Authorized.</h1>;
            }}
            authenticating={Oidc}
          >
            <Switch>
              {/* <Route exact={true} path="/unauthorized" component={Unauthorized} />
              <Route exact={true} path="/missing-license" component={MissingLicense} />
              <Route exact={true} path="/not-found" component={NotFound} /> */}
              <Route exact={true} path="/unauthorized" element={<ErrorLayout><Unauthorized /></ErrorLayout>} />
              <Route exact={true} path="/missing-license" element={<ErrorLayout><MissingLicense /></ErrorLayout>} />
              <Route exact={true} path="/not-found" element={<ErrorLayout><NotFound /></ErrorLayout>} />

              <OidcSecure>
                <SetAuthToken>
                  <SetOidcRoutesContext>
                    <I18nextProvider i18n={i18next}>
                      <WhiteLabelProvider
                        configuration={{
                          centerpointApiUrl: config.centerpointBaseUrl
                        }}
                      >
                        <InteractAdminAppContextProvider>
                          <AdminLayoutRoute
                            exact={true}
                            path="/dashboard-old"
                            nav="dashboard-old"
                            component={Dashboard}
                            featureEnabled={true}
                            onLeave={() => currentDataContext.dispatchCurrentDataEvent('', null)}
                          />
                          <AdminLayoutRoute
                            exact={true}
                            path="/"
                            nav="home"
                            component={Dashboardv2}
                            featureEnabled={true}
                            onLeave={() => currentDataContext.dispatchCurrentDataEvent('', null)}
                          />

                          {/* Mfdx */}
                          {/* <AdminLayoutRoute
                            path="/admin/mfdx/:environment"
                            nav="mfdx_settings"
                            subMenuNav="mfdx"
                            component={MfdxSettings}
                            //featureEnabled={featureFlags.attributeStore}
                            featureEnabled={true}
                          />
                          <AdminLayoutRoute
                            path="/admin/mfdxdoc/:documentId/:environment"
                            nav="mfdx_setting"
                            subMenuNav="mfdx"
                            component={MfdxDocument}
                            //featureEnabled={featureFlags.attributeStore}
                            featureEnabled={true}
                          /> */}
                          {/* Extras */}
                          <AdminLayoutRoute
                            path="/extrs/deployments"
                            nav="extrs_deployments"
                            subMenuNav="extrs"
                            component={ExtrasDeployments}
                            featureEnabled={true}
                            onLeave={() => currentDataContext.dispatchCurrentDataEvent('', null)}
                          />
                          <AdminLayoutRoute
                            path="/extrs/templates"
                            nav="extrs_templates"
                            subMenuNav="extrs"
µ                            component={ExtrasTemplates}
                            featureEnabled={true}
                            onLeave={() => currentDataContext.dispatchCurrentDataEvent('', null)}
                          />
                          <AdminLayoutRoute
                            path="/extrs/environments"
                            nav="extrs_environments"
                            subMenuNav="extrs"
µ                            component={ExtrasEnvironments}
                            featureEnabled={true}
                            onLeave={() => currentDataContext.dispatchCurrentDataEvent('', null)}
                          />
                          <AdminLayoutRoute
                            path="/extrs/filemanager"
                            nav="extrs_filemanager"
                            subMenuNav="extrs"
µ                            component={ExtrasFileManager}
                            featureEnabled={true}
                            onLeave={() => currentDataContext.dispatchCurrentDataEvent('', null)}
                          />
                          <AdminLayoutRoute
                            path="/extrs/filetroubleshoot"
                            nav="extrs_filetroubleshoot"
                            subMenuNav="extrs"
µ                            component={ExtrasFileTroubleshoot}
                            featureEnabled={true}
                            onLeave={() => currentDataContext.dispatchCurrentDataEvent('', null)}
                          />
                          <AdminLayoutRoute
                            path="/extrs/sandbox"
                            nav="extrs_sandbox"
                            subMenuNav="extrs"
µ                            component={ExtrasSandbox}
                            featureEnabled={true}
                            onLeave={() => currentDataContext.dispatchCurrentDataEvent('', null)}
                          />
                          {/* Templates */}
                          <AdminLayoutRoute
                            exact={true}
                            path="/templates/update"
                            subMenuNav="templates"
                            nav="templates_update"
                            component={TemplatesMaintainance}
                            featureEnabled={true}
                            onLeave={() => currentDataContext.dispatchCurrentDataEvent('', null)}
                          />
                          <AdminLayoutRoute
                            exact={true}
                            path="/templates/publish/:groupId/:orderId/:command?"
                            subMenuNav="templates"
                            nav="templates_publish"
                            component={TemplatesPublish}
                            featureEnabled={true}
                            onLeave={() => currentDataContext.dispatchCurrentDataEvent('', null)}
                          />
                          <AdminLayoutRoute
                            exact={true}
                            path="/templates/processes/:groupId/:environmantId/:fileId"
                            subMenuNav="templates"
                            nav="templates_processes"
                            component={LogicFileProcessManager}
                            featureEnabled={true}
                            onLeave={() => currentDataContext.dispatchCurrentDataEvent('', null)}
                          />
                          {/* Kubernetes deployment */}
                          <AdminLayoutRoute
                            exact={true}
                            path="/environments/deployment/env/:envGrpId"
                            nav="environments_createdeploymentenv"
                            subMenuNav="environments"
                            component={EnvironmentsCreateDeploymentEnv}
                            featureEnabled={true}
                            onLeave={() => currentDataContext.dispatchCurrentDataEvent('', null)}
                          />
                          <AdminLayoutRoute
                            exact={true}
                            path="/environments/deployment/srv/:settingsId/:update/:serverId?"
                            nav="environments_v2"
                            subMenuNav="environments_v2"
                            component={EnvironmentsCreateDeploymentSrv}
                            featureEnabled={true}
                            onLeave={() => currentDataContext.dispatchCurrentDataEvent('', null)}
                          />
                          {/* Enviroments */}
                          {/* <AdminLayoutRoute
                            exact={true}
                            path="/environments/integrations"
                            subMenuNav="environments"
                            nav="environments_integrations"
                            component={EnvironmentsIntegrationList}
                            featureEnabled={true}
                            onLeave={() => currentDataContext.dispatchCurrentDataEvent('', null)}
                          />
                          <AdminLayoutRoute
                            exact={true}
                            path="/environments/integration/:settingsId"
                            nav="environments_integration"
                            subMenuNav="environments"
                            component={EnvironmentsIntegration}
                            featureEnabled={true}
                            onLeave={() => currentDataContext.dispatchCurrentDataEvent('', null)}
                          /> */}
                          {/* <AdminLayoutRoute
                            exact={true}
                            path="/environments/groups/"
                            nav="environments_integrationgroups"
                            subMenuNav="environments"
                            component={EnvironmentsGroups}
                            featureEnabled={true}
                            onLeave={() => currentDataContext.dispatchCurrentDataEvent('', null)}
                          /> */}
                          <AdminLayoutRoute
                            exact={true}
                            path="/environments/group/:settingsId"
                            nav="environments_v2"
                            subMenuNav="environments_v2"
                            component={EnvironmentsGroup}
                            featureEnabled={true}
                            onLeave={() => currentDataContext.dispatchCurrentDataEvent('', null)}
                          />
                          {/* <AdminLayoutRoute
                            exact={true}
                            path="/environments/templates"
                            subMenuNav="environments"
                            nav="environments_templates"
                            component={TemplatesEnvironmentList}
                            featureEnabled={true}
                            onLeave={() => currentDataContext.dispatchCurrentDataEvent('', null)}
                          /> */}
                          {/* <AdminLayoutRoute
                            exact={true}
                            path="/environments/template/:settingsId"
                            subMenuNav="environments"
                            nav="environments_template"
                            component={TemplatesEnvironment}
                            featureEnabled={true}
                          /> */}
                          <AdminLayoutRoute
                            exact={true}
                            path="/templates/server/:envId/:settingsId"
                            subMenuNav="environments_v2"
                            nav="environments_v2"
                            component={TemplatesServer}
                            featureEnabled={true}
                          />
                          <AdminLayoutRoute
                            exact={true}
                            path="/templates/server/tbs/:envId/:settingsId"
                            subMenuNav="environments_v2"
                            nav="environments_v2"
                            component={TemplatesServerTroubleShoot}
                            featureEnabled={true}
                          />
                          {/* <AdminLayoutRoute
                            exact={true}
                            path="/environments/webeditors"
                            subMenuNav="environments"
                            nav="environments_webeditors"
                            component={EnvironmentsWebEditorList}
                            featureEnabled={true}
                            onLeave={() => currentDataContext.dispatchCurrentDataEvent('', null)}
                          />
                          <AdminLayoutRoute
                            exact={true}
                            path="/environments/webeditor/:settingsId"
                            nav="environments_webeditor"
                            subMenuNav="environments"
                            component={EnvironmentsWebEditor}
                            featureEnabled={true}
                            onLeave={() => currentDataContext.dispatchCurrentDataEvent('', null)}
                          /> */}
                          {/* <AdminLayoutRoute
                            exact={true}
                            path="/environments/archieves"
                            subMenuNav="environments"
                            nav="environments_archieves"
                            component={EnvironmentsArchieveList}
                            featureEnabled={true}
                            onLeave={() => currentDataContext.dispatchCurrentDataEvent('', null)}
                          />
                          <AdminLayoutRoute
                            exact={true}
                            path="/environments/archieve/:settingsId"
                            nav="environments_archieve"
                            subMenuNav="environments"
                            component={EnvironmentsArchieve}
                            featureEnabled={true}
                            onLeave={() => currentDataContext.dispatchCurrentDataEvent('', null)}
                          /> */}
                          <AdminLayoutRoute
                            exact={true}
                            path="/environments/v2"
                            nav="environments_v2"
                            subMenuNav="environments_v2"
                            component={Environmentsv2}
                            featureEnabled={true}
                            onLeave={() => currentDataContext.dispatchCurrentDataEvent('', null)}
                          />
                          <AdminLayoutRoute
                            exact={true}
                            path="/environments/v2/:groupId/:environmentId"
                            nav="environments_v2"
                            subMenuNav="environments_v2"
                            component={EditEnvrionment}
                            featureEnabled={true}
                            onLeave={() => currentDataContext.dispatchCurrentDataEvent('', null)}
                          />
                          {/* Attribute Store */}
                          <AdminLayoutRoute
                            path="/admin/attributestore/:environment"
                            nav="attributestore_documents"
                            subMenuNav="attributestore"
                            component={AttributeStoreDocumentList}
                            guard={isSppUserGuard}
                            featureEnabled={true}
                            onLeave={() => currentDataContext.dispatchCurrentDataEvent('', null)}
                          />
                          <AdminLayoutRoute
                            path="/admin/attributestoredoc/:documentId/:environment"
                            nav="attributestore_document"
                            subMenuNav="attributestore"
                            component={AttributeStoreDocument}
                            guard={isSppUserGuard}
                            featureEnabled={true}
                            onLeave={() => currentDataContext.dispatchCurrentDataEvent('', null)}
                          />
                        </InteractAdminAppContextProvider>

                      </WhiteLabelProvider>

                    </I18nextProvider>
                  </SetOidcRoutesContext>
                </SetAuthToken>
              </OidcSecure>
            </Switch>
          </AuthenticationProvider>
        </Router>
        <Toaster />
      </RecoilRoot>
    </div>
  );
}

//#region Contexts

//#region CurrentDataContext


//#endregion

//#endregion

function AdminLayoutRoute({
  component: Component,
  featureEnabled,
  nav,
  subMenuNav,
  licenseId,
  guard,
  requiredFeatureCode,
  ...rest
}) {
  const { hasLicense } = useLicense();
  const { hasFeature } = useFeature();

  const guardMet = guard ? guard() : true;

  return (
    <Route
      {...rest}
      render={props => (
        <ProtectedRoute
          hasLicense={hasLicense(licenseId) && hasFeature(requiredFeatureCode) && (guard ? guard() : true)}
          featureEnabled={featureEnabled}
        >
          <AdminLayout nav={nav} subMenuNav={subMenuNav} {...props}>
            <Component {...props} />
          </AdminLayout>
        </ProtectedRoute>
      )}
    />
  );
}

const ProtectedRoute = ({ featureEnabled, hasLicense, children }) => {
  return featureEnabled && hasLicense ? <>{children}</> : <Redirect to={'/'} />;
};

const SetOidcRoutesContext = ({ children }) => {
  const loadAction = useToastAction();
  const [userProfile, setUserProfile] = useState({});
  const [customerInfo, setCustomerInfo] = useState({});

  const loadUserContextData = async () => {
    loadAction.execute(async () => {
      const userProfilePromise = getUserProfile();
      const customerPromise = getCustomerInfo();
      const res = await Promise.all([userProfilePromise, customerPromise]);

      i18next.changeLanguage(res[0].languageCode);
      setUserProfile(res[0]);
      setCustomerInfo(res[1]);
    }, 'Failed to load user data');
  };

  useEffect(() => {
    loadUserContextData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    !loadAction.isExecuting && (
      <OidcRoutesContext.Provider
        value={{
          userProfile,
          customerInfo
        }}
      >
        {children}
      </OidcRoutesContext.Provider>
    )
  );
};

/**
 * Small route wrapper that gets the oidc user and sets the axios auth header
 */
const SetAuthToken = ({ children }) => {
  const { oidcUser, events } = useReactOidc();

  setAuthHeader(oidcUser.access_token);

  useEffect(() => {
    if (!events) return;
    events.addUserSignedOut(addUserSignedOut);
    return () => {
      events.removeUserSignedOut(addUserSignedOut);
    };
  }, [events]);

  const addUserSignedOut = () => {
    const userManager = getUserManager();
    userManager.removeUser();
    window.location.reload(false);
  };

  return <>{children}</>;
};

export default App;
