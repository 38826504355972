import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'

import { TwTableContext } from '../../contexts';
import { ButtonGroup } from "../ButtonGroup"
import { Label } from "../Label"
import { Select } from "../Select"

const TYPES = {
  normal: 'normal',
  simple: 'simple'
};

const TwTable = ({ children, additionalClass = 'mt-8', noDivider=false, ...props }) => {
  const settings = useContext(TwTableContext);

  return (
    <div className={`flow-root ${additionalClass}`} {...props}>
      {/* <div className={`-mx-4 -my-2 sm:-mx-6 lg:-mx-8 overflow-x-auto `}> */}
      <div className={`-mx-3 -my-1 sm:-mx-1 lg:-mx-1 overflow-x-auto `}>
        {/* <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"> */}
        <div className="inline-block min-w-full py-2 align-middle sm:px-1 lg:px-1">
          <div className={`${settings?.type === TYPES.simple ? "" : "shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg dark:ring-gray-700 overflow-hidden"} `}>
            <table className={`min-w-full ${noDivider ? "" : "divide-y divide-gray-300 dark:divide-gray-700"}`}>
              {children}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

const TwTableHead = ({ children, additionalClass = '', ...props }) => {
  const settings = useContext(TwTableContext);

  return (
    <thead className={`${settings?.type === TYPES.simple ? "" : "bg-gray-50 dark:bg-gray-700" } text-gray-900 dark:text-gray-400 ${additionalClass}`} {...props}>
      <tr>
        {children}
      </tr>
    </thead>
  );
}

const TwTableHeader = ({ children, sortBy, tableActions, additionalClass = 'pl-4', ...props }) => {
  const sortProps = {}
  const sortEnabled = sortBy && tableActions
  if (sortEnabled) {
    sortProps.onClick = () => tableActions.orderBy(sortBy)
  }
  return (
    <th
      scope="col"
      className={`py-3 pr-3 text-left text-sm font-semibold ${sortEnabled ? 'cursor-pointer' : ''} ${additionalClass}`}
      {...sortProps}
      {...props}
    >
      {children}
      {sortEnabled && tableActions.getSortIcon(sortBy)}
    </th>
  );
}

const TwTableBody = ({ children, additionalClass = '', noDivider=false, ...props }) => {
  const settings = useContext(TwTableContext);

  return (
    <tbody className={`${settings?.type === TYPES.simple ? "" : "bg-white dark:bg-gray-800" } ${noDivider ? "" : " divide-y divide-gray-300 dark:divide-gray-700"} ${additionalClass}`} {...props}>
      {children}
    </tbody>
  );
}

const TwTableRow = ({ children, additionalClass = '', addDivider = false, useHover = true, usePointerCursonOnHover = true, ...props }) => {
  return (
    <tr className={`text-gray-500 dark:text-gray-200${useHover ? " hover:bg-gray-100 dark:hover:bg-gray-900" : ""} ${useHover && usePointerCursonOnHover ? " cursor-pointer" : ""}${addDivider ? " border-b border-gray-300 dark:border-gray-700" : ""} ${additionalClass}`} {...props}>
      {children}
    </tr>
  );
}

const TwTableColumn = ({ children, additionalClass = '', ...props }) => {
  return (
    <td
      //className={`whitespace-nowrap px-6 py-4 text-sm ${additionalClass}`}
      className={`py-4 text-sm px-2 md:px-6 ${additionalClass}`}
      {...props}
    >
      {children}
    </td>
  );
}

const TwTableLink = ({ children, to, additionalClass = '', ...props }) => {
  return (
    <Link
      to={to}
      className={`font-semibold text-indigo-600 hover:text-indigo-900 cursor-pointer dark:text-blue-500 dark:hover:text-blue-400 ${additionalClass}`}
      {...props}
    >
      {children}
    </Link>
  );
}

const TwTableFoot = ({ children, additionalClass = '', ...props }) => {
  const settings = useContext(TwTableContext);

  return (
    <tfoot className={`${settings?.type === TYPES.simple ? "" : "bg-gray-50 dark:bg-gray-700" } text-gray-900 dark:text-gray-400 ${additionalClass}`} {...props}>
      <tr>
        {children}
      </tr>
    </tfoot>
  );
}

const TwTableFootColumn = ({ children, additionalClass = '', ...props }) => {
  const settings = useContext(TwTableContext);

  return (
    <td
      className={`whitespace-nowrap px-6 py-4 text-sm ${additionalClass}`}
      {...props}
    >
      {children}
    </td>
  );
}

const TwPagination = ({ numPages, currPage, setCurrPage, itemsPerPage, setItemPerPage, maxItems, onLoadPage, pageRanges = null, additionalClass = '', siblingCount = -1, boundaryCount = -1, ...props }) => {
  const settings = useContext(TwTableContext);
  const btnClassName = "w-12 justify-center";

  if (siblingCount <= 0)
    siblingCount = 3;
  if (boundaryCount <= 0)
    boundaryCount = 1;
  
  const startItem = (currPage * itemsPerPage) + 1;
  let endItem = (currPage + 1) * itemsPerPage;
  if (endItem > maxItems)
    endItem = maxItems;

  let buttons = [];
  buttons.push({
    content: <>
      <span className="sr-only">Previous</span>
      <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
      </> ,
      onClick: () => { if (currPage > 0) setCurrPage(--currPage); },
      item: "-1",
    current: false,
    className: btnClassName
  });

  let startBoundary = currPage < (boundaryCount + siblingCount + 1) ? boundaryCount + siblingCount + 1 : boundaryCount;
  if (startBoundary > numPages)
    startBoundary = numPages;
  let endBoundary = currPage > numPages - (boundaryCount + siblingCount + 1) ? boundaryCount + siblingCount + 1 : boundaryCount;
  if (endBoundary > numPages)
    endBoundary = numPages;
  const endNum = (numPages - endBoundary);

  // Start part
  for (let i = 0; i < startBoundary ; i++)
  {
    const idx = i;
    buttons.push({
      content: (idx + 1),
      item: true,
      current: idx === currPage,
      onClick: () => setCurrPage(idx),
      className: btnClassName
    });
  }
  if (startBoundary === boundaryCount && endNum > 0)
  {
    buttons.push({
      content: "...",
      item: false,
      className: btnClassName
    });
  }

  // Sibling part
  if (currPage > startBoundary && currPage < endNum && endNum > 0)
  {
    const centerNum = (currPage - ((siblingCount - 1) / 2));
    for (let i = 0; i < siblingCount; i++)
    {
      const idx = i + centerNum;
      buttons.push({
        content: (idx + 1),
        item: true,
        current: idx === currPage,
        onClick: () => setCurrPage(idx),
        className: btnClassName
      });
    }
  }

  // End part
  if (numPages > startBoundary)
  {
    if (endBoundary === boundaryCount)
    {
      buttons.push({
        content: "...",
        item: false,
        className: btnClassName
      });
    }
    for (let i = 0; i < endBoundary; i++)
    {
      const idx = i + endNum;
      buttons.push({
        content: (idx + 1),
        item: true,
        current: idx === currPage,
        onClick: () => setCurrPage(idx),
        className: btnClassName
      });
    }
  }
  buttons.push({
    content: <>
      <span className="sr-only">Previous</span>
      <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
      </> ,
    onClick: () => { if (currPage < (numPages - 1)) setCurrPage(++currPage); },
    item: "-1",
    current: false
  });

  if (endItem === 0)
    return (<></>);
  return (
    <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
      <div className="sm:flex-1">
        <Label as="p" additionalClass='text-left'>
          Showing <span className="font-medium">{startItem >= endItem ? endItem : startItem}</span> to <span className="font-medium">{endItem}</span> of{' '}
          <span className="font-medium">{maxItems}</span> results
        </Label>
      </div>
      {pageRanges && setItemPerPage &&
      <div className="mx-4 inline-flex">
        <Label as="p" additionalClass="mr-2 my-auto">
          Items per page:
        </Label>
        <Select value={itemsPerPage} options={pageRanges.map(x => { return {name: x, value: x};})} onChange={(e) => { setItemPerPage(parseInt(e.target.value));}} additionalClass='flex-initial w-24' />
        </div>
      }
      <ButtonGroup buttons={buttons} />
    </div>
  );
}

TwTable.types = TYPES;
TwTable.Head = TwTableHead;
TwTable.Header = TwTableHeader;
TwTable.Body = TwTableBody;
TwTable.BodyCol = TwTableColumn;
TwTable.BodyRow = TwTableRow;
TwTable.Link = TwTableLink;
TwTable.Foot = TwTableFoot;
TwTable.FootCol = TwTableFootColumn;
TwTable.Pagination = TwPagination;

export { TwTable };
